import { IncomingMessage } from 'http';

import { ApolloClient, NormalizedCacheObject } from '@apollo/client/core';
import * as Sentry from '@sentry/nextjs';

import GET_FEATURE_FLAG from '../graphql/LXGetFlagOrganization.v4.b2b.graphql';
import {
  LXGetFlagOrganization,
  LXGetFlagOrganizationVariables,
} from '../graphql/types/LXGetFlagOrganization';

export async function getIsNewSidebarEnabled(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  req: IncomingMessage,
  organizationSlug?: string,
): Promise<boolean> {
  try {
    const { data } = await apolloClient.query<
      LXGetFlagOrganization,
      LXGetFlagOrganizationVariables
    >({
      query: GET_FEATURE_FLAG,
      variables: { slug: organizationSlug },
      context: {
        clientName: 'v4.b2b',
        headers: {
          cookie: req.headers.cookie,
        },
      },
    });

    const isSidebarV2Enabled = data?.b2bOrganizationCurrent?.features?.find(
      feature => feature?.slug === 'new-sidebar-v2',
    );

    return !!isSidebarV2Enabled;
  } catch (error) {
    const extra = {
      page: 'Home User B2B',
      query: 'LXGetFlagOrganization',
      organizationSlug,
    };

    Sentry.captureException(error, { extra });

    return false;
  }
}
