import { useCallback, useState } from 'react';

import {
  getCommonAmplitudeProperties,
  getSourceEventParams,
  useAmplitude,
} from '@crehana/analytics';
import { useFeatureFlag } from '@crehana/feature-flags/react';
import type { CourseEnrollment } from '@crehana/web/Graphql/types';

import {
  Courses,
  CoursesWithRules,
  MyCoursesWithRules,
  type CoursesProps,
} from '@/shared/components';
import type { TUserEnrollment } from '@/shared/components/MyCoursesWithRules/hooks/useCourseEnrollments/types';
import Breadcrumb from '@/views/LearnDashboard/components/Breadcrumb';
import SectionTitle from '@/views/LearnDashboard/components/SectionTitle';
import { useLearnDashboardContext } from '@/views/LearnDashboard/context';
import { useCrehanaTranslation } from '@/views/LearnDashboard/hooks';

import { LEARN_DASHBOARD_PAGE_NAME } from '../../../constants';

const CoursesSection: React.FC<CoursesProps> = props => {
  const { logEvent } = useAmplitude();
  const { t } = useCrehanaTranslation();
  const { isPlayroomEnabled, me } = useLearnDashboardContext();
  const { flagValue: activatedFlag, loading: loadingFF } = useFeatureFlag(
    'CERTIFICATION_WITH_QUIZZES',
  );
  const { flagValue: activatedRedesignFlag, loading: loadingRedesignFF } =
    useFeatureFlag('LEARNING_QUIZZES_MASSIFICATION');
  const [sentAmplitudeEventRender, setSentAmplitudeEventRender] =
    useState(false);

  const sendAmplitudeEventRender = useCallback(
    (courses: CourseEnrollment[] = []) => {
      // Note: We only want to send the event once per page load. Not on every re-render, refetch, change pagination, etc.
      if (sentAmplitudeEventRender) {
        return;
      }

      const eventProperties: Readonly<Record<string, number | string>> = {
        ...getSourceEventParams(),
        ...getCommonAmplitudeProperties(me, isPlayroomEnabled),
        'Courses Enrolled Count': courses.length,
        'Quiz Certification Available': activatedFlag ? 'Yes' : 'No',
      };

      logEvent({
        event: 'Render Home Courses Enrolled',
        data: eventProperties,
      });

      setSentAmplitudeEventRender(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPlayroomEnabled, me, sentAmplitudeEventRender],
  );

  const sendAmplitudeEventRenderRedesign = useCallback(
    (courses: TUserEnrollment[] = []) => {
      // Note: We only want to send the event once per page load. Not on every re-render, refetch, change pagination, etc.
      if (sentAmplitudeEventRender) {
        return;
      }

      const eventProperties: Readonly<Record<string, number | string>> = {
        ...getSourceEventParams(),
        ...getCommonAmplitudeProperties(me, isPlayroomEnabled),
        'Courses Enrolled Count': courses.length,
        'Quiz Certification Available': 'Yes',
      };

      logEvent({
        event: 'Render Home Courses Enrolled',
        data: eventProperties,
      });

      setSentAmplitudeEventRender(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isPlayroomEnabled, me, sentAmplitudeEventRender],
  );

  const renderContent = () => {
    if (loadingFF || loadingRedesignFF) {
      return;
    }

    if (activatedRedesignFlag) {
      return (
        <MyCoursesWithRules
          {...props}
          me={me}
          onCoursesChange={sendAmplitudeEventRenderRedesign}
          amplitudePageName={LEARN_DASHBOARD_PAGE_NAME}
        />
      );
    }

    return activatedFlag ? (
      <CoursesWithRules
        {...props}
        me={me}
        onCoursesChange={sendAmplitudeEventRender}
        amplitudePageName={LEARN_DASHBOARD_PAGE_NAME}
      />
    ) : (
      <Courses
        {...props}
        me={me}
        onCoursesChange={sendAmplitudeEventRender}
        amplitudePageName={LEARN_DASHBOARD_PAGE_NAME}
      />
    );
  };

  return (
    <>
      <Breadcrumb
        items={[{ label: t('LABEL_SIDEBAR_ITEM_MY_COURSES_BREADCRUMB') }]}
      />
      <SectionTitle title={t('LABEL_SIDEBAR_ITEM_MY_COURSES_TITLE')} />
      {renderContent()}
    </>
  );
};

export default CoursesSection;
