import { Fragment } from 'react';

import { LazyImage } from '@crehana/ui';

import type { OrganizationInfo } from '@/views/LearnDashboard/hooks/getOrganizationTheme';

type TOrganizationIsoTypeProps = {
  isB2B: boolean;
  isDark?: boolean;
  organizationSlug?: string;
  organizationInfo?: OrganizationInfo;
};

const OrganizationIsoType: React.FC<TOrganizationIsoTypeProps> = ({
  isB2B,
  isDark,
  organizationSlug,
  organizationInfo,
}) => {
  if (!isB2B || !organizationInfo?.configuration) {
    return <Fragment />;
  }

  let currentUrlImage = isDark
    ? organizationInfo.configuration.darkModeIsotype
    : organizationInfo.configuration.isotype;
  currentUrlImage = currentUrlImage || organizationInfo.configuration.isotype;

  if (!currentUrlImage) {
    return <Fragment />;
  }

  return (
    <LazyImage
      src={currentUrlImage}
      imgClassName="object-contain"
      alt={organizationSlug || ''}
      imgxParams={{ h: 24, w: 24 }}
      className="h-24 w-24"
      isTransparent
      isDark={isDark}
    />
  );
};

export default OrganizationIsoType;
