import { initializeApolloClient } from '@crehana/apollo-client';
import {
  getG11nAndTranslationsFromServer,
  type TCustomGetServerSideProps,
} from '@crehana/next';

import { LEARN_DASHBOARD_PAGE_KEY } from '../constants';
import {
  getOrganizationTheme,
  type OrganizationInfo,
} from '../hooks/getOrganizationTheme';
import locales from '../i18n';
import type { IAppProps } from '../types';
import { getIsNewSidebarEnabled } from './getIsNewSidebarEnabled';

const getServerSideProps: TCustomGetServerSideProps<IAppProps> = async ({
  req,
  query,
}) => {
  const apolloClient = initializeApolloClient(req);
  const g11nData = await getG11nAndTranslationsFromServer({
    headers: req.headers,
    pageKey: LEARN_DASHBOARD_PAGE_KEY,
    defaultTranslations: locales,
  });

  const organizationSlug = query.organization as string | undefined;
  let organizationInfo: OrganizationInfo | undefined;
  let isSidebarV2Enabled = false;

  if (organizationSlug) {
    const [_organizationInfo, _isSidebarV2Enabled] = await Promise.all([
      getOrganizationTheme({
        client: apolloClient,
        organizationSlug,
        pageName: LEARN_DASHBOARD_PAGE_KEY,
      }),
      getIsNewSidebarEnabled(apolloClient, req, organizationSlug),
    ]);

    organizationInfo = _organizationInfo;
    isSidebarV2Enabled = _isSidebarV2Enabled;
  }

  return {
    props: {
      ...g11nData,
      isAppFeatureEnabled: true,
      isDesktopFeatureEnabled: true,
      organizationInfo,
      organizationSlug,
      isSidebarV2Enabled: !!isSidebarV2Enabled,
    },
  };
};

export default getServerSideProps;
